(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.vehicles.list.controller:VehiclesListCtrl
   *
   * @description
   *
   */
  angular
    .module('events.vehicles.list')
    .controller('VehiclesListCtrl', VehiclesListCtrl);

  function VehiclesListCtrl($scope, $state, $stateParams, PAGE_SIZE, Restangular, event) {

    var vm = this;

    vm.event = event.data;
    vm.vehicles = [];

    // md-data-table
    vm.meta = {
      limit: 500,
      order: 'name',
      page: $stateParams.p,
      total: 0,
      query: ''
    };

    vm.search = search;
    vm.query = query;
    vm.paginate = paginate;

    // Drop the case for a query string search
    if ($stateParams.q !== '') {
      vm.meta.query = $stateParams.q.toLowerCase();
    }

    // Run the initial search
    search();

    // Run the search when the state changes
    // In the state (set in the routes file) we use reloadOnSearch = false so we can change states as the user types in the search field without losing focus on the input field
    $scope.$on('$locationChangeSuccess', function () {
      search();
    });

    // Run the search
    function search(page, limit) {

      if (angular.isUndefined(page)) {
        page = 1;
      }

      if (angular.isUndefined(limit)) {
        limit = vm.meta.limit;
      }

      var args = {
        search: vm.meta.query,
        limit: vm.meta.limit,
        page: vm.meta.page,
        total: 'true',
        inc: 'VehiclePeople'
      };
      Restangular.one('events', vm.event.id).all('vehicles').getList(args).then(function (vehicles) {
        vm.meta.total = vehicles.meta.total;
        vm.vehicles = vehicles;
      });
    }

    // Callback for query changes
    function query() {
      vm.meta.page = 1; // reset search to first page for new query
      $state.go('.', {q: vm.meta.query, p: vm.meta.page});
    }

    // Callback for md-on-paginate
    function paginate(page, limit) {
      $state.go('.', {q: $stateParams.q, p: page});
    }
  }
}());
